
.sayabout {
  padding-top: 13 * $p-basic;
  padding-bottom: 9 * $p-basic;
  background-color: $c-white;
  @media screen and (max-width: 650px) {
    padding-top: 4 * $p-basic;
    padding-bottom: 9 * $p-basic;
  }
}

.sayabout .container {
    position: relative;
}

.sayabout_container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 716px;
    position: relative;
}


.sayabout__item {
    text-align: center;
}

.sayabout__txt {
    margin-bottom: 5 * $p-basic;
}

.sayabout__txt h2 {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    @include head1-45;
    @media screen and (max-width: 650px) {
        @include headBook-30;
    }    
}

.sayabout .top-slider__buttons {
    padding-right: 40px;
    bottom: -60px;
    @media screen and (max-width: 650px) {
        padding-right: 0px;
        text-align: center;
    }  
}

.sayabout__img {
    margin-bottom: 3 * $p-basic;
}

.sayabout-video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin-bottom: 3 * $p-basic;
  
}
.sayabout-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
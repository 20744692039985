footer {
    background-color: $c-blackLight;
    padding-top: 6 * $p-basic;
    color: $c-white;
    @media screen and (max-width: 650px) {
        padding-top: (4 * $p-basic) ;
    }  
}

.foot__info {
    background-color: $c-white;
    @include basicXSmall-12;
    color: $c-blackLight;
    padding-top: 2 * $p-basic;
    padding-bottom: 2 * $p-basic;
}

.foot__info-wrap {
    display: flex;
}

.foot__info-copy {
    text-align: left;
    flex: 1;
}
.foot__info-font {
    text-align: right;
    flex: 1;
}

.foot__logo {
    @include basicBig-18;
    font-family: $PuristaSemi;
    text-transform: uppercase;
    text-align: center;
    padding-bottom: 6 * $p-basic;
    border-bottom: 1px solid $c-greyDark;
    @media screen and (max-width: 650px) {
        padding-bottom: (4 * $p-basic) ;
    }  
}

.foot__logo img {
    width: 60px;
    height: 67px;
    display: inline-block;
    margin-right: 2 * $p-basic;
     
}

.foot__nav {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-top: 6 * $p-basic;
    padding-bottom: 4 * $p-basic;
    border-bottom: 1px solid $c-greyDark;
    @media screen and (max-width: 650px) {
        padding-top: 4 * $p-basic;
        padding-bottom: (2 * $p-basic) ;
    } 
}

.foot__nav ul {
    margin: 0px;
    padding: 0px (2 * $p-basic);
    list-style-type: none;
    flex: 1;
}

.foot__nav li {
   display: block;
   margin-bottom: $p-basic;
   flex: 1;
}

.foot__nav a {
   color: $c-white;
   display: inline-block;
   text-transform: uppercase;
   padding-bottom: $p-basic;
   position: relative;
    font-family: $PuristaSemi;
    font-weight: normal;
   &::before {
       @include trans();
       position: absolute;
       bottom: 8px;
       left: 0px;
       width: 0%;
       height: 3px;
       background-color: red;
       content: "";
   }
    &:hover {
        text-decoration: none;
        &::before {
            @include trans();
            width: 100%;
        }    
    }
}

.foot__nav li.active a::before {
   width: 100%;
}

.foot__other {
    display: flex;
    align-items: flex-end;
    padding-top: 4 * $p-basic;
    padding-bottom: 8 * $p-basic;
    @media screen and (max-width: 1000px) {
        flex-wrap: wrap;
    }
}

.foot__other-form {
    width: 350px;
    margin-right: 4 * $p-basic;

    @media screen and (max-width: 1125px) {
        width: 250px;
    }
    @media screen and (max-width: 740px) {
        margin-left: auto;
        margin-right: auto;
    }
    
}

.foot__other-form-wrap {
    display: flex;
    align-items: flex-end;
}

.foot__other-form-input {
    flex:1;
    margin-right: $p-basic;
}

.foot__other-form-button {
    width: 85px;
}

.foot__other-form-button button {
    border-radius: 40px;
    height: 32px;
    text-align: center;
    background-color: $c-white;
    color: $c-blackLight;
    text-transform: uppercase;
    height: 32px;
    border: none;
    width: 85px;
}

.foot__other-form label {
    color: $c-white;
    font-family: $PuristaSemi;
    margin-bottom: $p-basic;
    text-transform: uppercase;
    font-weight: normal;
}

.foot__other-cont {
    flex: 1;
    display: flex;
    padding: 0 (4 * $p-basic);
    border-left: 1px solid $c-greyDark;
    border-right: 1px solid $c-greyDark;
    @media screen and (max-width: 740px) {
        width: 100%;  
        flex: none;
        text-align: center;
        flex-wrap: wrap;
        padding-top: 4 * $p-basic;
        border-left: none;
        border-right: none;
        padding-left: 0;
        padding-right: 0;
    }
}
.foot__other-cont  a {
    color: $c-white;
    font-weight: normal;
}

.foot__other-contRight {
    flex: 1;
    text-align: right;
    text-transform: uppercase;
    font-family: $PuristaSemi;
    font-weight: normal;
    text-decoration: underline;
    @media screen and (max-width: 450px) {
        padding-top: 4 * $p-basic;
        text-align: center;
        flex: none;
        width: 100%;
    }
}

.foot__other-contLeft {
    flex: 1;
    text-align: left;
    text-transform: uppercase;
    font-family: $PuristaSemi;
    text-decoration: underline;
    @media screen and (max-width: 450px) {

        text-align: center;
        flex: none;
        width: 100%;
    }
}

.foot__other-social {
    width: 230px;
    text-align: right;
    margin-left: 4 * $p-basic;

    @media screen and (max-width: 1000px) {
        width: 100%;  
        margin-left: 0px;      
        text-align: center;
        padding-top: 4 * $p-basic;
    }
}
.foot__other-social-support {
    color: $c-green;
    font-family: $PuristaSemi;
    display: inline-block;
    text-transform: uppercase;
    margin-right: $p-basic;
}
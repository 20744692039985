.list {
    margin-top: 4 * $p-basic;
}

.list h1 {
    display: block;
    @include head1-45;
    padding-left: 3 * $p-basic;
    padding-right: 3 * $p-basic;
    padding-bottom: 3 * $p-basic;
    border-bottom: 1px solid $c-greyLight;
    margin-bottom: 5 * $p-basic;

    @media screen and (max-width: 768px) {
        @include headBook-30;
        margin-bottom: 3 * $p-basic;
    }
    @media screen and (max-width: 650px) {
        @include headBook-30;
        padding-left: 0 * $p-basic;
        padding-right: 0 * $p-basic;
        padding-bottom: 2 * $p-basic;
        margin-bottom: 1px;
    }
}
.list-wrap {
    display: flex;
    flex-wrap: wrap;
}

.list-menu {
    width: 34 * $p-basic;
    @media screen and (max-width: 768px) {
            width: 20 * $p-basic;
    } 
    @media screen and (max-width: 650px) {
            width: 100%;
            margin-bottom: 3 * $p-basic;
    }     
}

.list-menu ul {
    margin: 0;
    padding: 0px 7 * $p-basic;
    list-style-type: none;
    @media screen and (max-width: 768px) {
            padding: 0px 3 * $p-basic;
    } 
    @media screen and (max-width: 650px) {
            padding: 0px;
            display: flex;
            flex-wrap: wrap;
    }  
}

.list-menu li {
    display: block;
    @media screen and (max-width: 650px) {
            display: inline-block;
            width: auto;
            margin-right: 1px;
            margin-bottom: 1px;
    }  
}

.list-menu a {
    @include trans();
    display: inline-block;
    text-transform: uppercase;
    color: $c-blackLight;
    text-decoration: none;
    font-weight: normal;
    position: relative;
    width: auto;
      @include basicBig-18;
        font-family: $PuristaSemi;
        margin-bottom: 2 * $p-basic;

@media screen and (max-width: 650px) {
      
            margin: 0;
            padding:  $p-basic (2 * $p-basic);
            background-color: $c-blackLight;
            color: $c-white;
        }

    &::after {
        @include trans();
        content: "";
        position: absolute;
        width: 0%;
        height: 5px;
        background-color: $c-red;
        left: 0px;
        bottom: 0px;
       @media screen and (max-width: 650px) {
            display: none;
        }
        
    }
        @media screen and (max-width: 768px) {
        font-size: 0.875rem;
        line-height: 1.0625rem;
    }
        @media screen and (max-width: 650px) {
        font-size: 0.875rem;
        line-height: 1.0625rem;
         &:hover {
            background-color: $c-red;
         }   
    }
     &:hover {
        @include trans();
        color: $c-blackLight; 
        text-decoration: none;
        
;      &::after {
            @include trans();
            width: 100%;            
        }
    }
}

.list-menu li.active a {
    @media screen and (max-width: 650px) {      
            background-color: $c-red;
            color: $c-white;
    }
    &::after {
        width: 100%;
    }
}

.list-art {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
}

.list-art-book {
    
    margin-right: 3 * $p-basic;
    margin-bottom: 11 * $p-basic;

    @media screen and (min-width: 1141px) {
        width: calc((100% - (2 * (6 * #{$p-basic}))) / 3);
        &:nth-child(3n) {
            margin-right: 0;
        }
    }
    @media screen and (max-width: 1140px) {
        width: calc((100% - (1 * (6 * #{$p-basic}))) / 2);
        &:nth-child(2n) {
            margin-right: 0;
        }
    }
    @media screen and (max-width: 768px) {
        width: calc((100% - (1 * (4 * #{$p-basic}))) / 2);
       
    }
    @media screen and (max-width: 540px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 7 * $p-basic;
    }
}

.list-art-book-img  {
    display: block;
    position: relative;
} 
.list-art-book-img img {
    @include trans(0.3s, all);
    display: block;
    margin: 0 auto;
    -webkit-box-shadow: 0px 12px 35px -17px rgba(0,0,0,0.65); 
    box-shadow: 0px 12px 35px -17px rgba(0,0,0,0.65);

    &:hover {
        @include trans(0.3s, all);
        -webkit-box-shadow: 0px 19px 35px -15px rgba(0,0,0,0.65); 
    box-shadow: 0px 19px 35px -15px rgba(0,0,0,0.65);
    }

}
.list-art-book-info {
   margin-top: 4 * $p-basic;
   text-align: left;
}

.list-art-book-info h1 {
    @include headTitle-21;
    font-family: $PuristaSemi;
    margin-bottom: 0;
    padding: 0;
    border-bottom: none;
}

.list-art-book-info h1 a {
    color: $c-blackLight;
    text-decoration: none;
    @include trans(0.3s, color);
}

.list-art-book-info h1 a:hover {
    @include trans(0.3s, color);
    color: $c-red;
}
.list-art-book-author {
    @include basicBig-18;
    color: $c-blackLight;
    @include trans(0.3s, color);
    display: inline-block;
    font-family: $Purista;
    font-weight: normal;
    text-decoration: none;
    margin-right: $p-basic;
}

.list-art-book-author:hover {
    @include trans(0.3s, color);
    color: $c-red;
    font-weight: normal;
    text-decoration: none;
}

.list-art-book-perex {
    padding-top: 5 * $p-basic;
    display: block;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        background-color: $c-greyLink;
        width: 108px;
        height: 4px;
        left: 0px;
        top: 17px;
    }
}

.list-art-book-addCart {
   
    display: block;
    border: none;
    background-color: transparent;
    border-radius: 0;
    margin-top: $p-basic;
    padding: 0px 0px 0px;
    min-height: 18px;
   
    @include basicBig-18;
    font-family: $PuristaSemi;
   
}
.list-art-label--wrap {
    position: absolute;
    width: 100%;
    right: -16px;
    top: -16px;
    width: 64px;
    @media screen and (max-width: 540px) {
        right: 0px;
        top: -32px;
    }
}
.list-art-label {
    width: 64px;
    height: 64px;    
    background-color: $c-red;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1 * $p-basic;
    box-shadow: 0px 3px 6px 0 rgba($c-black,0.37);
    @include basicXSmall-12;
    text-transform: uppercase;
    color: $c-white;
    
}

.list-art-publisher {
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;
    padding: $p-basic / 2;
    color: $c-white;
    background-color: rgba($c-red,0.6);
    text-align: center;
    text-transform: uppercase;
}
.list-art-author {
    @include trans(0.3s, color);
    color: $c-black;
    text-decoration: none;
    @include basic-16;
    font-weight: normal;
    
}

.list-art-author img {
    display: block;
    width: 100%;
}

.list-art-author:hover {
    @include trans(0.3s, color);
    color: $c-red;
    text-decoration: none;
}

.list-art-author__inner {
    padding: (3 * $p-basic) (2 * $p-basic) 0px (2 * $p-basic);
    line-height: 1.9rem;
    @media screen and (max-width: 540px) {
        padding: (2 * $p-basic) 0px 0px 0px;
    }
}

.list-art-author__inner h2 {
    @include basicBigXL-21;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 1 * $p-basic;
}

.list-art__eventDate {
    color: $c-red;
    font-weight: bold;
    opacity: 1;
    margin-bottom: 1 * $p-basic;
}

.in-past .list-art__eventDate {
    color: $c-blackLight;
    opacity: 0.3;
}


.addProductPrint, .addProductPrintWrap, .addProductEbook {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin-left: 4px;
}



.addProductPrintBtn {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-color: black;
    border: none;
    color: $c-white;
    text-align: center;
    cursor: pointer;
    font-family: $PuristaSemi;
    font-size: 1.2rem;
    @include trans();
}
.addProductPrintBtn:hover {
  background-color: $c-red;
  @include trans();
}
/* TOOLTIP START */
.tooltip {
  position: relative;
  z-index: 1;
 
}

.tooltip::before {
  position: absolute;
  top: 50%;
  left: 100%;
  display: none;
  width: 100px;
  padding: 10px 9px 11px 9px;
  margin-left: 15px;
  background: $c-greyDark;
  border-radius: 4px;
  box-shadow: 0 12px 24px -8px rgba($c-greyDark, 0.3), 0 1px 3px 0 rgba($c-greyDark, 0.25);
  color: $c-white;
  content: attr(data-tooltiptext);
  text-align: center;
  text-decoration: none;
  transform: translateY(-50%);
  font-size: 1.1rem;
  font-family: $PuristaSemi;
}
.tooltip:hover::before {
  display: block;
}

.tooltip.tooltip-left::before {
  right: 100%;
  left: initial;
  margin: initial;
  margin-right: 15px;
}
.tooltip.tooltip-top::before {
  top: initial;
  bottom: calc(100% + 15px);
  left: 50%;
  margin-top: 15px;
  margin-right: 0;
  margin-left: 0;
  transform: translateX(-50%);
}
.tooltip.tooltip-bottom::before {
  top: 100%;
  left: 50%;
  margin-top: 15px;
  margin-right: 0;
  margin-left: 0;
  transform: translateX(-50%);
}

/* TOOLTIP STOP */
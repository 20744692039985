html,
body {
  padding: 0;
  margin: 0;
  font-family: $Purista;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.625rem;
  color: $c-blackLight;
}

p {
  margin: 0;
}
i, em {
  color: $c-blackLight;
}
a {
  text-decoration: none;
  font-weight: bold;
}

* {
  box-sizing: border-box;
}

strong {
  font-weight: bold;
}

/* KONTAIERY */
.container {
  width: 100%;
  padding-right: 2 * $p-basic;
  padding-left: 2 * $p-basic;
  margin-right: auto;
  margin-left: auto;

  @media only screen and (min-width: 640px) {
    padding-right: 2 * $p-basic;
    padding-left: 2 * $p-basic;
  }

  @media only screen and (min-width: 768px) {
    padding-right: 3 * $p-basic;
    padding-left: 3 * $p-basic;
  }

  @media only screen and (min-width: 990px) {
    padding-right: 3 * $p-basic;
    padding-left: 3 * $p-basic;
  }

  @media only screen and (min-width: 1024px) {
    padding-right: 4 * $p-basic;
    padding-left: 4 * $p-basic;
  }

  @media only screen and (min-width: 1200px) {
    max-width: 1264px;
    padding-right: 4 * $p-basic;
    padding-left: 4 * $p-basic;
  }
}
.container--basicPad {
  padding-top: 6 * $p-basic;
  padding-bottom: 4 * $p-basic;
}

.mb-6 {
  margin-top: 6 * $p-basic;
}

.uk-link {
  color: $c-blackLight;
  text-decoration: underline;
}
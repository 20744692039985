/* MENU */

.header {
    padding-top: 5 * $p-basic;
    padding-bottom: 7 * $p-basic;
     @media screen and (max-width: 650px) {
        padding-top: 2 * $p-basic;
        padding-bottom: 2 * $p-basic;
     }   
}
.header--wrap {
    display: flex;
    align-items: center;
    position: relative;
}
.header--logo {
    width: 165px;
    @media screen and (max-width: 900px) {
            width: 100px;
        }
}
.header--logo img{
    width: 116px;
    height: 130px;
    @media screen and (max-width: 900px) {
           width: 70px;
           height: 79px;
        }
}

.header--opener {
    background-size: 29px 18px;
    display: block;
    background-color: $c-powder;
    border: none;
    width: 40px;
    height: 40px;
    background-image: url(../../images/menu-open.svg);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: -40px;
    top: 0px;
    
    z-index: -1;
    @media screen and (min-width: 651px) {
        display: none;
    }    
}
.header--collapse {
    flex: 1;
    position: relative;
    display: flex;
    @media screen and (max-width: 650px) {
            padding: 4 * $p-basic;
           display: block;
           position: fixed;
           right: -250px;
           top: 0px;
           width: 250px;
           background-color: $c-powder;
           height: 100vh;
           z-index: 100;
           @include trans();
    }

    &.open {
        @media screen and (max-width: 650px) {
            @include trans();
            -webkit-box-shadow: -6px 0px 6px 0px rgba(0,0,0,0.06); 
            box-shadow: -6px 0px 6px 0px rgba(0,0,0,0.06);
            right: 0px;
            
            .header--opener {
                background-image: url(../../images/menu-close.svg);
                -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.16); 
                box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.16);
            }
        }
    }
}

.header--menuTop {
    position: absolute;
    top: -70px;
    right: 110px;
    @media screen and (max-width: 900px) {
           top: -48px;
           right: 90px;
        }
    @media screen and (max-width: 650px) {
           position: relative;
           top: 0px;
           right: 0px;
           margin-top: 3 * $p-basic;
    }
}
.header--menuTop ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    @media screen and (max-width: 650px) {
            display: block;
    
     }   
    li {
        float: left;
        display: block;
        width: auto;
        margin-left: 4 * $p-basic ;
        &:first-child {
            margin-left: 0px;
        }
        @media screen and (max-width: 650px) {
           float: none;
           margin-bottom: $p-basic * 2;
           margin-left: 0 ;
        }
    }

    a {
        @include trans();
        display: block;
        text-transform: uppercase;
        color: $c-blackLight;
        text-decoration: none;
        font-weight: normal;
        position: relative;
        &::after {
            @include trans();
            content: "";
            position: absolute;
            width: 0%;
            height: 2px;
            background-color: $c-red;
            left: 0px;
            bottom: 0px;
        }
         @media screen and (max-width: 768px) {
           font-size: 0.875rem;
           line-height: 1.0625rem;
        }
         @media screen and (max-width: 650px) {
           font-size: 0.875rem;
            line-height: 1.0625rem;
        }
    }

     a:hover {
        @include trans();
        color: $c-red; 
        text-decoration: none;
;      &::after {
            @include trans();
            width: 100%;            
        }
    }

    .header--support a {
        padding: 10px 20px;
        line-height: normal;
        border-radius: 6px;
        display: inline-block;
        background-color: rgba($c-red,1);
        color: $c-white;
        text-decoration: none;
        @include trans();      
        &:hover {
            @include trans();
            background-color: rgba($c-red,0.6);
            text-decoration: none;
        }
        &::after {
            display: none;
        }
    }
}
.header--menuTop li.active a {
        &::after {
            width: 100%;
        }
}   
.header--basket {
    position: absolute;
    right: 4 * $p-basic;
    top: -40px;
    width: 58px;
    height: 58px;
    background-color: rgba(red,0);
    display: block;
    cursor: pointer;
    @media screen and (max-width: 650px) {
           right: 7* $p-basic;
            top: -2* $p-basic;
        }
}
.header--basket img {
    display: block;
    padding-top: $p-basic;
    padding-right: $p-basic;
    width: 35px;
    height: 35px;
    margin-left: auto;
}
.header--basketLabel {
    @extend .redCircle;
    width: 27px;
    height: 27px;
    position: absolute;
    left: 8px;
    top: 27px;
    color: $c-white;
    text-align: center;
    display: block;
    font-size: 0.9rem;
    padding-top: 1px;
}

.header--menuLeft, .header--menuRight {
    flex: 1;
    @media screen and (max-width: 650px) {
           flex: none;
        }
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: inline-block;
    }

    li {
        float: left;
        display: block;    
        @media screen and (max-width: 650px) {
           float: none;
           margin-bottom: $p-basic * 2;
        }    
    }

    a {
        color: $c-blackLight;
        display: block;
        font-size: 1.3125rem;
        line-height: 2.0625rem;
        font-family: $PuristaSemi;
        position: relative;
        text-transform: uppercase;
        &::after {
            @include trans();
            content: "";
            position: absolute;
            width: 0%;
            height: 5px;
            background-color: $c-red;
            left: 0px;
            bottom: 0px;
        }
        @media screen and (max-width: 900px) {
            font-size: 1.125rem;
            line-height: 1.9375rem;
        }
        @media screen and (max-width: 768px) {
           font-size: 1rem;
            line-height: 1.625rem;
        }
    }
     a:hover {
        @include trans();
        color: $c-red; 
        text-decoration: none;
;      &::after {
            @include trans();
            width: 100%;            
        }
    }
}

.header--menuLeft {
    li {
        margin-right: 7 * $p-basic;
        &:last-child {
            margin-right: 0px;
        }

        @media screen and (max-width: 1024px) {
            margin-right: 3 * $p-basic;
        }
        @media screen and (max-width: 900px) {
            margin-right: 2 * $p-basic;
        }
        @media screen and (max-width: 650px) {
           margin-right: 0px;
        }
    }
}

.header--menuRight {
    text-align: right;
    @media screen and (max-width: 650px) {
           text-align: left;
        }
    li {
        margin-left: 7 * $p-basic;
        &:first-child {
            margin-left: 0px;
        }

        @media screen and (max-width: 1024px) {
            margin-left: 3 * $p-basic;
        }
        @media screen and (max-width: 900px) {
            margin-left: 2 * $p-basic;
        }
        @media screen and (max-width: 650px) {
           margin-left: 0px;
        }
    }
}

.header--menuLeft li.active a, .header--menuRight li.active a {
    &::after {
            width: 100%;
        }
}
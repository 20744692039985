.book-wrap {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 7 * $p-basic;
    border-bottom: 1px solid $c-greyLink;
    margin-bottom: 7 * $p-basic;
    @media screen and (max-width: 768px) {
        padding-bottom: 5 * $p-basic;
        margin-bottom: 5 * $p-basic;
    }  
    @media screen and (max-width: 650px) {
        padding-bottom: 3 * $p-basic;
        margin-bottom: 3 * $p-basic;
    }  
}

.book-img {
    width: 40%;
    margin-right: 7 * $p-basic;
    position: relative;
    @media screen and (max-width: 860px) {
       width: 30%;
    }
   @media screen and (max-width: 650px) {
       width: 100%;
       margin-right: 0px;
       order: 2;
    }
}

.book-img img {
    width: 100%;
      @include trans(0.3s, all);
    -webkit-box-shadow: 0px 12px 35px -17px rgba(0,0,0,0.65); 
    box-shadow: 0px 12px 35px -17px rgba(0,0,0,0.65);

    &:hover {
        @include trans(0.3s, all);
        -webkit-box-shadow: 0px 19px 35px -15px rgba(0,0,0,0.65); 
    box-shadow: 0px 19px 35px -15px rgba(0,0,0,0.65);
    }
}

.book-info {
    flex: 1;
    @media screen and (max-width: 650px) {
       order: 1;
       flex: inherit;
       width: 100%;
       margin-bottom: 2 * $p-basic;
    }
}

.book-label--wrap {
    position: absolute;
    width: 100%;
    left: -32px;
    top: -32px;
    width: 64px;
    display: block;
    @media screen and (max-width: 540px) {
        left: -16px;
        top: -16px;
    }
}

.book-label {
    width: 64px;
    height: 64px;
    background-color: $c-red;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 6px 0 rgba($c-black,0.37);
    @include basicXSmall-12;
    text-transform: uppercase;
    color: $c-white;
    margin-bottom: 2  *$p-basic;
    @media screen and (max-width: 540px) {
        left: 0px;
        top: -32px;
    }
}
.book-author-wrap {
    margin-bottom: 3  * $p-basic;
}
.book-author {
    color: $c-black;
    text-decoration: none;
    @include trans();
    @include basicBigXL-21;
    font-weight: normal;
    display: inline-block;
    margin-right: 1 * $p-basic;
    &:hover {
        color: $c-red;
        text-decoration: none;
        @include trans();
        font-weight: normal;
    }
     @media screen and (max-width: 560px) {
        margin-right: 1  * $p-basic;
    }
}
.book-author:last-child {
    margin-right: 0;
}
.book h1 {
    @include headBook-30;
    margin-bottom: 5  * $p-basic;
    @media screen and (max-width: 560px) {
        @include headTitle-21;
        margin-bottom: 2  * $p-basic;
    }
}

.book-additional {
    margin-bottom: 8 * $p-basic;
    @media screen and (max-width: 768px) {
        margin-bottom: 5 * $p-basic;
    }
    @media screen and (max-width: 768px) {
        margin-bottom: 3 * $p-basic;
    }
}
.book-additional p {
  margin-bottom: 2 * $p-basic;
  text-indent: 2 * $p-basic;
}

.book-additional h3 {
  margin-bottom: 0;
  @include basic-16;
}


.book-shop {
    background-color: $c-greyBack;
    padding: 26px 20px;
    display: flex;
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
    }
}

.book-shop--toCart {
    width: calc(50% - (2 * #{$p-basic}));
    margin-right: 4 * $p-basic;
    @media screen and (max-width: 768px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 2  * $p-basic;
    }
}

.book-shop--info {
   flex: 1;
   @media screen and (max-width: 768px) {
        width: 100%;
        flex: none;
    }
}

.book-shop--info p {
   margin-bottom: $p-basic;
   min-height: 29px;
   display: block;
}

.book-shop--info strong {
   font-family: $PuristaSemi;
   font-weight: normal;
} 
.book-shope--addToCart {
    display: flex;
    align-items: center;
}

.book-shope--addToCart--price {
    display: block;
    flex: 1;    
    @include basicBigXL-21;
    font-weight: bold;
}

.book-shope--addToCart--addButton {
    display: flex;    
    width: 120px;
    margin-left: $p-basic;
}
.book-shope--addToCart--addButton input[type=number] {
    border: 0px;
    background-color: $c-white;
    height: 28px;
    padding: 2px 2px 2px 2px;
    width: 34px;
    text-align: center;
}
.book-shope--addToCart--addButton button {
    border: 0px;
    background-color: $c-black;
    height: 28px;
    padding: 2px 2px 2px 2px;
    width: 85px;
    text-align: center;
    color: $c-white;
    font-weight: normal;
}

.book-shop--isbn {
    display: flex;
    align-items: flex-start;
}

.book-shop--isbn--label {
    width: 5 * $p-basic;
    margin-right: 2 * $p-basic;
}

.book-shop--isbn--items {
   flex:1;
   font-family: $PuristaSemi;
   font-weight: normal;
}

.book-authorBox {
    display: flex;
    margin-bottom: 3 * $p-basic;
    padding-bottom: 3 * $p-basic;
    border-bottom: 1px solid $c-greyLight;
    @media screen and (max-width: 650px) {
        flex-wrap: wrap;
    }
    &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
    }
}

.book-authorBox__img {
    width: 151px;
    height: 151px;
    border-radius: 50%;
    display: block;
    margin-right: 6 * $p-basic;
    @media screen and (max-width: 650px) {
        margin-left: auto;
        margin-right: auto;
    }
}

.book-authorBox__txt {
    flex: 1;
    @media screen and (max-width: 650px) {
        width: 100%;
        flex: none;
        margin-top: 2 * $p-basic;
    }
}

.book-authorBox__txt h3 {
    @include headTitle-21;
    margin-bottom: 2 * $p-basic;
}

.book-authorBox__txt p {
    margin-bottom: 2 * $p-basic;
}

.book-authorBox__txt p:last-child {
    margin-bottom: 0px;
}

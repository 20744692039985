/* header and titles */

.btn {
  display: inline-block;
  width: auto;
  border: none;
  background-color: none;
  border-radius: $border-radius;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  padding: $p-basic (8 * $p-basic);
  background-color: $c-blackLight;
  color: $c-powder;
  text-transform: uppercase;
  @include basic-16;
  font-weight: bold;
  border: 2px solid $c-blackLight;
}
.btn:hover {
    background-color: rgba($c-blackLight,0);
    color: $c-blackLight;
}
/* header and titles */

h1 {
  @include head1-45;

  padding: 0 0 0 0;
  margin: 0 0 $p-basic 0;
  color: $c-blackLight;
  @media screen and (max-width: 768px) {
    @include head2-31;
  }
}
h2 {
  @include head2-31;

  padding: 0 0 0 0;
  margin: 0 0 (3 * $p-basic) 0;
  color: $c-blackLight;
  @media screen and (max-width: 768px) {
    @include headTitle-21;
  }
}

h3 {
  @include headTitle-21;

  padding: 0 0 0 0;
  margin: 0 0 (1 * $p-basic) 0;
  color: $c-blackLight;
  @media screen and (max-width: 768px) {
    @include headTitle-21;
  }
}

.basket {

}

.order {
    text-align: center;
    margin-bottom: 6 * $p-basic;
}
.basket h1 {
    @include head1-45; 
    text-align: center;;
    img {
        display: inline-block;
        width: 35px;
        height: 35px;
        position: relative;
        top: -6px;
        left: 0px;
    }
    
    @media screen and (max-width: 650px) {
        @include head2-31; 
    }
}

.basketInfoEmpty {
    padding-bottom: 2 * $p-basic;
    margin-bottom: 2 * $p-basic;
    margin-top: 4 * $p-basic;
    border-bottom: 1px solid $c-greyLink;
    text-align: center ;
    @include basic-16;
    font-weight: bold;
}

.basketInfoVat {
    padding-bottom: 2 * $p-basic;
    margin-bottom: 2 * $p-basic;
    border-bottom: 1px solid $c-greyLink;
    text-align: right;
    @include basic-16;
    font-weight: normal;
}

.basketInput {
    height: 27px;
    border-radius: 50px;
    text-align: center;
    width: 55px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.basketDeleteItem {
    
    background-image: url(../../images/icon_delete.svg);
    background-size: 21px 19px;
    background-repeat: no-repeat;
    background-position: center center;
    border: none;
    background-color: rgba($c-white,0);
    width: 25px;
    height: 25px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}

.basketTable td {
    border-bottom: 1px solid $c-greyLink;
}

.basketTable th {
    border-bottom: 4px solid $c-blackLight;
}

.basketButtonWrap {
    text-align: center;
    padding-top: 2 * $p-basic;
    padding-bottom: 2 * $p-basic;
}
.basketButton {
    background-color: $c-blackLight;;
    min-height: 27px;
    border-radius: 50px;
    text-align: center;
    color: $c-white;
    text-transform: uppercase;
    width: auto;
    padding: 8px 15px 5px 15px ;
    display: inline-block;
    margin-right: 2 * $p-basic;
    border: 2px solid $c-blackLight;
    cursor: pointer;
    @include trans();
    &:hover {
        @include trans();
        background-color: $c-white;;
        color: $c-blackLight;
    }
}
.basketButton:last-child {
    margin-right: 0;
}

.basketButton__rev {
    background-color: rgba($c-blackLight,0);
    color: $c-blackLight;
     &:hover {
        background-color: rgba($c-blackLight,1);
        color: $c-white;
     }   
}

.basketOrder {
    display: flex;
    @media screen and (max-width: 650px) {
        flex-wrap: wrap;
    }

}

.basketOrderPadd {
    padding: 2 * $p-basic;
}

.basketOrderPerson {
    width: calc((100% - (2 * #{$p-basic})) /2);
    margin-right: 4 * $p-basic;
    @media screen and (max-width: 650px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 2 * $p-basic;
    }
}

.basketOrderPayment {
    flex: 1;
     @media screen and (max-width: 650px) {
        width: 100%;
        flex: none;
        margin-right: 0;
    }
}

.basket h2 {
    @include basicBig-18;
    margin-bottom: 5px;
    margin-top: 20px;

}

.basketInputCode {
    
    height: 27px;
    border-radius: 50px;
    text-align: center;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 2 * $p-basic;
}
.labelCenter {
    text-align: center;
    margin-bottom: 2 * $p-basic;
}

.order__list {
    margin-top: 4 * $p-basic;
    width: 80px;
    margin-left: auto;
    margin-right: auto;
}
/* classic clearfix */
.clearfix::after {
  display: block;
  height: 0;
  clear: both;
  content: " ";
  font-size: 0;
  visibility: hidden;
}
* html .clearfix {
  zoom: 1;
} /* IE6 */
*:first-child + html .clearfix {
  zoom: 1;
} /* IE7 */

.redCircle {
    background-color: $c-red;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16); 
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);

}

.breadcrumb {
  padding: (2 * $p-basic) 0px 0px 0px;
  margin-bottom: (10 * $p-basic) ;
  border-bottom: 1px solid rgba($c-blackLight,0.2);
  text-align: right;
  @media screen and (max-width: 768px) {
        margin-bottom: (4 * $p-basic) ;
    }
  @media screen and (max-width: 650px) {
        margin-bottom: (4 * $p-basic) ;
    }  
}

.breadcrumb ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: inline-block;
}

.breadcrumb li {
  display: block;
  float: left;
  margin: 0 ;
  padding: 0px (4 * $p-basic) 0px 0px;
  background-image: url(../../images/arrow-right.svg);
  background-size: 6px 8px;
  background-repeat: no-repeat;
  background-position: top 10px right 16px;
}

.breadcrumb li:last-child {
  background-image: none;
  padding-right: 0px;
}
.breadcrumb a {
  @include basicSmall-14;
  color: $c-black;
  text-decoration: none;
  @include trans(0.3,color);
  font-weight: normal;
}
.breadcrumb a:hover {
  @include trans(0.3,color);
  color: $c-red;
  text-decoration: underline;
}

.hidden {
  display:none;
}

.tabs {
  margin-bottom: (4 * $p-basic) ;
  border-bottom: 1px solid rgba($c-blackLight,0.2);
  text-align: left;
  @media screen and (max-width: 768px) {
        margin-bottom: (3 * $p-basic) ;
    }
}

.tabs ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  width: auto;
}

.tab__opener {
  float: left;
  display: block;
  margin-right: 8 * $p-basic;
  @media screen and (max-width: 650px) {
    margin-right: 2 * $p-basic;
  }
}
.tab__opener:last-child {
  margin-right: 0px;
}

.tab__opener button {
  @include headTitle-21;
  display: block;
  @include trans(0.5,color);
  color: rgba($c-black, 0.3); 
  border: none;
  padding: 0px 0px 0px 0px;
  background-color: transparent; 
  cursor: pointer;
  &:hover {
    @include trans(0.5,color);
    color: rgba($c-black, 1);  
  }
  @media screen and (max-width: 650px) {
    @include basicBig-18;
    font-weight: bold;
  }
}

.tab__opener button.active {
  color: rgba($c-black, 1);  
}

.tabs__content__links {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.tabs__content__links li {
  display: block;
  margin: 0px 0px (2 * $p-basic) 0px;
  padding: 0;
}

.tabs__content__links li:last-child {
  margin: 0px 0px 0px 0px;  
}

.gallery {
  margin-bottom: 6 * $p-basic;
}
.gallery-wrap {
  display: flex; 
  flex-wrap: wrap;
} 

.gallery-item {
  padding-right: 32px;
  padding-bottom: 32px;
  width: 16%;
  @media screen and (min-width: 769px) {
   padding-right: 32px;
    padding-bottom: 32px;
    width: 16.6%;
  }
  @media screen and (max-width: 768px) {
   padding-right: 16px;
    padding-bottom: 16px;
    width: 25%;
  }
  @media screen and (max-width: 650px) {
   padding-right: 8px;
   padding-bottom: 16px;
    width: 50%;
    &:nth-child(2n) {
      padding-right: 0px;
      padding-left: 8px;
    }
  }
}

.gallery-item img {

  width: 100%;
}

h1.homepage {
  @include head1-45;
  text-align: center;
  margin-bottom: 7 * $p-basic;
  @media screen and (max-width: 650px) {
    margin-top: 2 * $p-basic;
    @include headBook-30;
    margin-bottom: 4 * $p-basic;
  } 
}

.mb-3 {
  margin-bottom: 3 * $p-basic;
}

.mb-6 {
  margin-bottom: 6 * $p-basic;
}


.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin-bottom: 8 * $p-basic;
    @media screen and (max-width: 768px){
        margin-bottom: 6 * $p-basic;
    }
    @media screen and (max-width: 650px){
        margin-bottom: 3 * $p-basic;
    }
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}



.support-us {
    padding-top: 10 * $p-basic;
    padding-bottom: 6 * $p-basic;
    @media screen and (max-width: 650px){
        padding-top: 4 * $p-basic;
        padding-bottom: 2 * $p-basic;
    }
}


.support-us h2 {
    text-align: center;
    @include head2-31;
    color: $c-grey;
    margin-bottom: 8 * $p-basic;
    @media screen and (max-width: 650px){
        margin-bottom: 4 * $p-basic;
    }
}

.support-us__wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

}

.support-us__item {
    width: 150px;
    display: block;
    margin-bottom: 4 * $p-basic;
    padding: 0px (2 * $p-basic);
    text-align: center;
    @media screen and (max-width: 650px){
         width: 140px;
         padding: 0px (1 * $p-basic);
    }
}

.support-us__item img {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.watch {
  padding-top: 13 * $p-basic;
  padding-bottom: 9 * $p-basic;
  background-color: $c-greyLight;
  @media screen and (max-width: 650px) {
    padding-top: 4 * $p-basic;
    padding-bottom: 2 * $p-basic;
  }
}

.watch h2 {
    margin-bottom: 7 * $p-basic;
    @media screen and (max-width: 650px) {
        margin-bottom: 3 * $p-basic;
    }
}

.watch__wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.watch__item {
    //width: calc((50% - (2 * #{$p-basic})) - 1);
    width: calc((50% - (2 * #{$p-basic})) - 1px);
    float: left;
    margin-bottom: 4 * $p-basic;
    -webkit-box-shadow: 0px 13px 16px 0px rgba(0,0,0,0.04); 
    box-shadow: 0px 13px 16px 0px rgba(0,0,0,0.04);
    &:nth-child(odd) {
        margin-right: 4 * $p-basic;
        @media screen and (max-width: 650px) {
            margin-right: 0;
        }
    }
    @media screen and (max-width: 650px) {
        width: 100%;
    }
}

.watch__item img {
    width: 100%;
}

.watch__item-txt {
    background-color: $c-white;
    padding: (4 * $p-basic) (3 * $p-basic) (10 * $p-basic) (3 * $p-basic) ;
    @media screen and (max-width: 650px) {
        padding: (2 * $p-basic) (2 * $p-basic) (4 * $p-basic) (2 * $p-basic) ;
    }
}

.watch__item-txt p {
    display: block;
    margin-top: 2 * $p-basic;
}    


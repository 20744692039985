/* FORMS */
/* form and buttons START */
.btn {
	border-radius: 0px;
	background-color: $c-red;
	//background-image: url("./img/arrow-right-white.svg");
	border: 2px solid $c-red;
	color: $c-black;
	display: inline-block;
	width: auto;
	text-align: center;
	padding: 15px 50px 15px 50px;
	font-size: 1.125rem;
	position: relative;
	webkit-transition: all 0.5s ease;
	-ms-transition: all 0.5s ease;
	transition: all 0.5s ease;
	
	white-space: normal;
	z-index: 1;
	font-weight: bold;
	text-transform: uppercase;
	cursor: pointer;
	&:hover,
	&:focus {
		background-color: rgba($c-red,0.6);
		color: $c-black;
		text-decoration: none;
	}
	&:disabled {
		background-color: rgba($c-red,0);
		border: 2px solid #DDDDDD;
		color: #DDDDDD;
	}
}

.btn-link-right {
	display: inline-block;
	width: auto;
	height: 25px;
	padding-right: 28px;
	background-image: url(../../images/arrow-right.svg);
	background-size: 13px 19px;
	background-repeat: no-repeat;
	background-position: top 3px right 5px;
	color: $c-black;
	text-decoration: none;
	@include trans(0.5s,all);
}
.btn-link-right:hover {
	@include trans(0.5s,all);
	color: $c-black;
	text-decoration: none;
	color: $c-red;
	background-position: top 3px right 0px;
}

.btn-link-left {
	display: block;
	width: 100%;
	max-width: 650px;
	min-height: 25px;
	padding-left: 28px;
	background-image: url(../../images/arrow-right.svg);
	background-size: 13px 19px;
	background-repeat: no-repeat;
	background-position: top 3px left 0px;
	color: $c-black;
	text-decoration: underline;
	@include trans(0.5s,all);
}
.btn-link-left:hover {
	@include trans(0.5s,all);
	color: $c-black;
	text-decoration: none;
	color: $c-red;
	text-decoration: underline;
	background-position: top 3px left 5px;
}
	.er {
		background-color: $c-red;
		color: $c-white;
		padding: 20px;
		text-align: left;
		font-weight: 700;
		margin-bottom: $p-basic;
	}

	.ch {
		background-color: $c-green;
		color: $c-white;
		padding: 20px;
		text-align: left;
		font-weight: 700;
		margin-bottom: $p-basic;
	}

	label {
		display: inline-block;
		width: 100%;		
		margin-bottom: 5px;
		padding-left: 10px;
		font-weight:bold;
		text-align: left;
		font-size: 1.125rem;
		color: $c-black;
	}

	input[type="text"], input[type="email"],
	textarea {
		width: 100%;		
		display: block;
		margin-bottom: 0px;
		background-color: $c-white;
		padding: 2px 10px 2px 10px;
		height: 32px;
		border-radius: 25px;
		margin-bottom: 15px;
		margin-top: 0px;
		font-size: 1.125rem;
		border: 1px solid #DDDDDD;
		color: $c-black;

		&:disabled {
			color: #C1C1C1;
			background-color: rgba(#DDDDDD, .5);
		}
		&:focus, &:active {
			border: 1px solid #DDDDDD;
		}
	}

	textarea {
		height: 100px;
		border-radius: 25px;
	}
	.selectBox {
		width: 100%;		
		display: block;
		margin-bottom: 30px;
		background-color: $c-white;
		padding: 14px 10px 13px 10px;
		border-radius: 0px;
		margin-top: 0px;
		font-size: 1.125rem;
		border: 1px solid #DDDDDD;
		background-image: url(../../images/arrow-down.svg);
		background-repeat: no-repeat;
		background-position: top 22px right 15px;
        background-size: 7px 9px;
		padding-right: 0px;
        
	}

	select {
		-moz-appearance: window;
		-webkit-appearance: none;
		padding-right: 50px;
		position: relative;
		background-color: transparent;
		background-repeat: no-repeat;
		background-position: center right;
		border: none;
		width: 100%;
		font-size: 1.125rem;
	}

	input.radok + label {
		position: relative;
		display: block;
		padding: 3px 0px 0px 28px;
		background-image: url(../../images/radio-off.svg);
		background-size: 21px 21px;
        min-height: 21px;
		color: $c-black;
		background-repeat: no-repeat;
		background-position: left top 5px;
		@include basic-16;
        font-weight: normal;
		cursor: pointer;
		top: 0px;
		width: auto;
		vertical-align: middle;
		margin-bottom: $p-basic;
		cursor: pointer;
        font-family: $Purista;
        span {
           font-family: $PuristaSemi;
           display: inline-block;
           margin-right: 2 * $p-basic;
        }				
	}

	input.radok:disabled + label {
		opacity: 0.2;
	}

	input.radok:checked + label {
		background-image: url(../../images/radio-on.svg);
	}

	input.radok {
		display: none;
	}

	input.checkok + label {
		position: relative;
		display: inline-block;
		padding: 4px 0px 0px 35px;
		background-image: url(../../images/check-off.svg);
		background-size: 23px 22px;
		color: $c-black;
		background-repeat: no-repeat;
		background-position: left top 5px;
		@include basic-16;
		cursor: pointer;
		margin-right: 70px;
		top: 0px;
		width: auto;
		min-height: 40px;
		vertical-align: middle;
		margin-bottom: 20px;
		cursor: pointer;
		font-weight: normal;

		&:last-child {
			margin-right: 0px;
		}
	}

	input.checkok:disabled + label {
		opacity: 0.4;
	}

	input.checkok:checked + label {
		padding: 4px 0px 0px 35px;
    	background-size: 30px 29px;
		background-position: left top;
		background-image: url(../../images/check-on.svg);
	}

	input.checkok {
		display: none;
	}
	
	input,
	textarea,
	select {
		outline: none;

		&:focus {
			outline: none;
		}
	}

	label a {
		color: $c-blackLight;
		text-decoration: underline;
		@include trans();
		&:hover {
			@include trans();
			color: $c-red;
		}
		cursor: pointer;
	}

/* form and buttons STOP */

.contacts {
    display: flex;
    gap: 70px;
    align-items: flex-start;
    margin-bottom: 6 * $p-basic;
    @media screen and (max-width: 768px) {
        
        gap: 50px;
   }
    @media screen and (max-width: 650px) {
         margin-bottom: 3 * $p-basic;
         flex-wrap: wrap;
    }
}

.contacts p {
    margin-bottom: 2 * $p-basic ;
}

.contacts a {
    color: $c-blackLight;
    text-decoration: underline;
    @include trans();
    &:hover {
        @include trans();
        color: $c-red;
    }
}

.contacts p:last-child {
    margin-bottom: 0;
}

.contact {
   
    flex: 1 1 0px;
    @media screen and (max-width: 650px) {
         width: 100%;
        margin-right: 0;
        margin-bottom: 6 * $p-basic;
    }
}

.contact-support {
    flex: 1 1 0px;
    @media screen and (max-width: 650px) {
        margin-top: 3 * $p-basic;
        width: 100%;
        flex: none;
        margin-right: 0;        
    }

}

.support p {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0px;
    }

}

.support p a {
   color: $c-green;
   text-decoration: underline;
}
/* Webfont: Purista-BoldItalic */@font-face {
    font-family: 'Purista';
    src: url('../../../static/fonts/PuristaBoldItalic.eot'); /* IE9 Compat Modes */
    src: url('../../../static/fonts/PuristaBoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../../static/fonts/PuristaBoldItalic.woff') format('woff'), /* Modern Browsers */
         url('../../../static/fonts/PuristaBoldItalic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../../static/fonts/PuristaBoldItalic.svg#Purista-BoldItalic') format('svg'); /* Legacy iOS */
    font-style: italic;
    font-weight: bold;
    text-rendering: optimizeLegibility;
}

/* Webfont: Purista-Bold */@font-face {
    font-family: 'Purista';
    src: url('../../../static/fonts/PuristaBold.eot'); /* IE9 Compat Modes */
    src: url('../../../static/fonts/PuristaBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../../static/fonts/PuristaBold.woff') format('woff'), /* Modern Browsers */
         url('../../../static/fonts/PuristaBold.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../../static/fonts/PuristaBold.svg#Purista-Bold') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: bold;
    text-rendering: optimizeLegibility;
}

/* Webfont: Purista */@font-face {
    font-family: 'Purista';
    src: url('../../../static/fonts/PuristaMedium.eot'); /* IE9 Compat Modes */
    src: url('../../../static/fonts/PuristaMedium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../../static/fonts/PuristaMedium.woff') format('woff'), /* Modern Browsers */
         url('../../../static/fonts/PuristaMedium.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../../static/fonts/PuristaMedium.svg#Purista') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Purista-Italic */@font-face {
    font-family: 'Purista';
    src: url('../../../static/fonts/PuristaMediumItalic.eot'); /* IE9 Compat Modes */
    src: url('../../../static/fonts/PuristaMediumItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../../static/fonts/PuristaMediumItalic.woff') format('woff'), /* Modern Browsers */
         url('../../../static/fonts/PuristaMediumItalic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../../static/fonts/PuristaMediumItalic.svg#Purista-Italic') format('svg'); /* Legacy iOS */
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}
/* Webfont: Purista-SemiBold */@font-face {
    font-family: 'PuristaSemiBold';
    src: url('../../../static/fonts/PuristaSemibold.eot'); /* IE9 Compat Modes */
    src: url('../../../static/fonts/PuristaSemibold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../../static/fonts/PuristaSemibold.woff') format('woff'), /* Modern Browsers */
         url('../../../static/fonts/PuristaSemibold.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../../static/fonts/PuristaSemibold.svg#Purista-SemiBold') format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: Purista-SemiBoldItalic */@font-face {
    font-family: 'PuristaSemiBold';
    src: url('../../../static/fonts/PuristaSemiboldItalic.eot'); /* IE9 Compat Modes */
    src: url('../../../static/fonts/PuristaSemiboldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../../static/fonts/PuristaSemiboldItalic.woff') format('woff'), /* Modern Browsers */
         url('../../../static/fonts/PuristaSemiboldItalic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../../static/fonts/PuristaSemiboldItalic.svg#Purista-SemiBoldItalic') format('svg'); /* Legacy iOS */
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}
@mixin basicCarousel-34 {
    font-size: 2.125rem;
    line-height: 2.9125rem;
    font-family: $Purista;
}
@mixin basicBigXL-21 {
    font-size: 1.3125rem;
    line-height: 2.25rem;
    font-family: $Purista;
}
@mixin basicBig-18 {
    font-size: 1.125rem;
    line-height: 1.9375rem;
    font-family: $Purista;
}
@mixin basic-16 {
    font-size: 1rem;
    line-height: 1.625rem;
    font-family: $Purista;
}
@mixin basicSmall-14 {
    font-size: 0.875rem;
    line-height: 1.0625rem;
    font-family: $Purista;
}
@mixin basicXSmall-12 {
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-family: $Purista;
}

@mixin headCarousel-63 {
    font-size: 3.9375rem;
    line-height: 4.687rem;
    font-family: $Purista;
    font-weight: bold;
}

@mixin head1-45 {
    font-size: 2.8125rem;
    line-height: 3.4375rem;
    font-family: $Purista;
    font-weight: bold;
}

@mixin head2-31 {
    font-size: 1.9375rem;
    line-height: 2.3125rem;
    font-family: $Purista;
    font-weight: bold;
}


@mixin headBook-30 {
    font-size: 1.875rem;
    line-height: 2.4375rem;
    font-family: $Purista;
    font-weight: bold;
}

@mixin headTitle-21 {
    font-size: 1.3125rem;
    line-height: 2.0625rem;
    font-family: $Purista;
    font-weight: bold;
}

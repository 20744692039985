.top-slider {
    margin-bottom: 8 * $p-basic;
    position: relative;
    @media screen and (max-width: 650px) {
        margin-bottom: 4 * $p-basic;
    } 
}

.top-slider__item {
    background-position: left top;
    background-repeat: repeat;
    padding-top: 5 * $p-basic;
    padding-bottom: 5 * $p-basic;
    background-color: $c-greyBack;
    color: $c-black;
    text-decoration: none;
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
}

.top-slider__inner {
    display: flex;
    @media screen and (max-width: 550px) {
        flex-wrap: wrap;
    } 
}

.top-slider__img {
    width: 40%;
    margin-right: 5 * $p-basic;
    @media screen and (max-width: 650px) {
        margin-right: 3 * $p-basic;
    } 
    @media screen and (max-width: 550px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 3 * $p-basic;
    } 
}

.top-slider__img img {
    max-width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.top-slider__txt {
    flex: 1;
    color: $c-black;
    @media screen and (max-width: 550px) {
        flex: none;
        width: 100%;
    } 
}

.top-slider__txt h2 {
    @include headCarousel-63;
    
    @media screen and (max-width: 990px) {
        @include head1-45;
        
    } 
    @media screen and (max-width: 650px) {
        @include headBook-30;
    } 
}

.top-slider__subheadline {
    @include basicCarousel-34;
    margin-top: 7 * $p-basic;
    font-family: $PuristaSemi;
    @media screen and (max-width: 990px) {
        @include basicBigXL-21;    
        font-family: $PuristaSemi;
        margin-top: 2 * $p-basic;    
    } 
    @media screen and (max-width: 650px) {
        @include basicBig-18;
        font-family: $PuristaSemi;
        margin-top: 2 * $p-basic;
    } 
}
.top-slider__txtcontent {
    @include basicBig-18;
    margin-top: 4 * $p-basic;
    font-family: $PuristaSemi;
    @media screen and (max-width: 990px) {
        @include basicBig-18;    
        font-family: $PuristaSemi;
        margin-top: 2 * $p-basic;    
    } 
    @media screen and (max-width: 650px) {
        @include basicBig-18;
        font-family: $PuristaSemi;
        margin-top: 2 * $p-basic;
    } 
}
.top-slider__buttons {
    position: absolute;
    bottom: -23px;
    text-align: right;
    width: 100%;
}

.top-slider__buttonNext, .top-slider__buttonPrev {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-image: url(../../images/arrow-right-circle.svg);
    background-size: 40px 40px;
    background-position: center center;
    background-repeat: no-repeat;
    transform: rotate(180deg);
    border: none;
    background-color:transparent;
    cursor: pointer;
    @include trans(0.5s,all);
    &:hover {
        @include trans(0.5s,all);
        transform: rotate(195deg);
        outline: none;
        border: none;
    }
    &:active {
        outline: none;
        border: none;
    }
    &:focus {
        outline: none;
        border: none;
    }
}

.top-slider__buttonPrev {
    transform: rotate(0deg);
    margin-left: 2  *$p-basic;
    &:hover {
        @include trans(0.5s,all);
        transform: rotate(-15deg);
    }
}
.article h1 {
    margin-bottom: 3 * $p-basic;
    text-align: center;
    @media screen and (max-width: 650px){
        margin-bottom: $p-basic;
    }
}

.article-cont {
    width: 100%;
    max-width: 1040px;
    margin-left: auto;
    margin-right: auto;
}

.article-cont a {
    color: $c-blackLight;
    text-decoration: underline;
}

.article-img {
    width: 100%;
    margin-bottom: 8 * $p-basic;
    @media screen and (max-width: 768px){
        margin-bottom: 6 * $p-basic;
    }
    @media screen and (max-width: 650px){
        margin-bottom: 3 * $p-basic;
    }
}

.article-birth {
    text-align: center;
    display: block;
    margin-bottom: 9 * $p-basic;
    @media screen and (max-width: 768px){
        margin-bottom: 6 * $p-basic;
    }
    @media screen and (max-width: 650px){
        margin-bottom: 3 * $p-basic;
    }
}
.article-content {
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 9 * $p-basic;
    @media screen and (max-width: 768px){
        margin-bottom: 6 * $p-basic;
    }
    @media screen and (max-width: 650px){
        margin-bottom: 3 * $p-basic;
    }
}


.article-content {
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 9 * $p-basic;
    @media screen and (max-width: 768px){
        margin-bottom: 6 * $p-basic;
    }
    @media screen and (max-width: 650px){
        margin-bottom: 3 * $p-basic;
    }
}

.article-content i, .article-content em {
  color: $c-blackLight;
}

.article-content p {
  margin-bottom: 2 * $p-basic;
  text-indent: 2 * $p-basic;
}

.article-content h3 {
  margin-bottom: 0;
  @include basic-16;
}

.article-date {
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;
    margin-bottom: 4 * $p-basic;
    @media screen and (max-width: 768px){
        margin-bottom: 2 * $p-basic;
    }
   
}
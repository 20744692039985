/* FONTS */
$Purista: 'Purista', Arial, Helvetica, sans-serif;
$PuristaSemi: 'PuristaSemiBold', Arial, Helvetica, sans-serif;

/* COLORS */
$c-white: rgba(255, 255, 255, 1);
$c-powder: rgba(255, 255, 252, 1);

$c-black: rgba(0, 0, 0, 1);
$c-blackLight: rgba(21, 21, 21, 1);
$c-red: rgba(232, 47, 33, 1);
$c-greyLight: rgba(246, 246, 245, 1);
$c-greyDark: rgba(112, 112, 112, 1);
$c-grey: rgba(185,187, 190, 1);
$c-green: rgba(39,165, 10, 1);
$c-greyLink: rgba(223, 223, 223, 1);
$c-greyBack: rgba(245, 245, 245, 1);
/* border-radius */
$border-radius: 25px;

/* paddings */
$p-basic: 8px;


